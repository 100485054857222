import * as React from "react";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import Col2 from "../components/Content/TextMedia/Col-2";
import StaticColumns from "../components/Content/FeatureColumn/Static";
import FeatureColumn from "../components/Content/FeatureColumn/Index";
import TimeLine from "../components/Content/Timeline/Index";

// assets
import iconDownload from "../assets/icons/icon-mail.svg";
import imageVideo from "../assets/images/content/about-us-video-pipes.jpg";
import brandConex from "../assets/logos/logo.svg";

// data
import videoItems from "../pages/static-pages/about.yaml";

// markup
const IndexPage: React.FC = () => {
  return (
    <Layout>
      <Seo
        title="About Us"
        description="For more than 110 years, Conex Bänninger has been the recognised leader in plumbing fittings, accessories and valves globally – offering a range of innovative and highly versatile jointing solutions."
      />
      <BannerBasic title="About Us" />
      <LeadIn
        brandHeading="A History of Innovation"
        summary="For more than 110 years, Conex Bänninger has been the recognised leader in plumbing fittings, accessories and valves globally – offering a range of innovative and highly versatile jointing solutions."
      />
      <Col2
        brandMediaUrl={brandConex}
        brandMediaTitle="Conex Bänninger Logo"
        brandMediaWidth="306px"
        summary="Since opening its doors in Giessen, Germany in 1909, Conex Bänninger has produced over 20 billion copper fittings, establishing an unrivalled reputation for outstanding quality, first class customer service and market leading expertise."
        backgroundMediaUrl={imageVideo}
        backgroundMediaTitle="Placeholder"
        mediaPositionClass="right"
        videoMediaType="youtube"
        videoMediaId="HpI7bS6z7Qc"
      />
      <StaticColumns />
      <FeatureColumn
        grid="medium"
        heading="See for yourself"
        divider={true}
        alignment="left"
        section="secondary"
        data={videoItems.items}
      />
      <TimeLine />
      <LeadIn
        section="primary"
        brandMediaUrl={iconDownload}
        brandMediaTitle="Download"
        brandMediaWidth="53px"
        summary="Contact us for further information"
        paddingSize="small"
        buttonValue="Contact"
        buttonUrl="/contact"
      />
    </Layout>
  );
};

export default IndexPage;
